<template>
  <!--    Grant Form-->
  <validation-observer
    ref="grantForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateGrantMode?'Update':'Record'} Grant `"
      :show="grantModalOpened"
      :show-overlay="deletingGrant"
      size="md"
      @onClose="closeGrantModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedGrantId"
            placeholder="All Grants"
            :options="grants"
            label-name="grant_label"
            value-name="id"
            name="grants"
            @input="populateGrantForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            icon="TrashIcon"
            name="delete_grant"
          >
            <b-dropdown-item
              id="cd_grant"
              @click="deleteGrant()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="grant_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetGrantForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-input
        v-model="grant.contributor_name"
        name="contributor_name"
        label="Contributor Name"
        rules="required"
      />
      <am-input
        v-model="grant.contributor_phone"
        name="contributor_phone"
        label="Contributor Phone"
        rules="required"
      />
      <am-select
        v-model="grant.mode_of_payment"
        name="mode_of_payment"
        label="Mode of Payment"
        rules="required"
        :options="modeOfPayments"
      />
      <am-input
        v-if="bank || momo"
        v-model="grant.transaction_id"
        name="transaction_id"
        label="Transaction/Tracking ID"
      />
      <am-input
        v-model="grant.amount"
        name="amount"
        :label="`Amount (${currencySymbol})`"
        rules="required|numeric"
      />
      <am-text-area
        v-model="grant.purpose"
        name="purpose"
        label="Contribution Purpose"
        placeholder="Contribution Purpose"
        rules="required"
      />
      <am-date-picker
        id="active_from"
        v-model="grant.date"
        name="date"
        label="Transaction Date"
        rules="required"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeGrantModal()"
          />
          <am-button
            name="save_grant"
            :label="updateGrantMode?'Update':'Create'"
            :disabled="invalid"
            :loading="grantLoading"
            loading-text="Loading..."
            @click="updateGrantMode?updateGrant():createGrant()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'
import AmDatePicker from '@/farmbusiness/components/AmDatePicker.vue'
import AmTextArea from '@/farmbusiness/components/AmTextArea.vue'

const cont = {
  id: null,
  contributor_name: null,
  contributor_phone: null,
  transaction_id: null,
  amount: null,
  purpose: null,
  mode_of_payment: null,
  date: null,
}
export default {
  name: 'GrantSetup',
  components: {
    AmTextArea,
    AmDatePicker,
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmSelect,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      grantModalOpened: false,
      updateGrantMode: false,
      grantLoading: false,
      deletingGrant: false,
      selectedGrantId: null,
      grant: { ...cont },
      modeOfPayments: [
        'Cash',
        'Mobile Money',
        'Bank',
      ],
      seasons: [],
    }
  },
  computed: {
    momo() {
      return this.grant.mode_of_payment === 'Mobile Money'
    },
    bank() {
      return this.grant.mode_of_payment === 'Bank'
    },
    memberGrant() {
      return this.transactionType?.includes('Member') || this.transactionType?.includes('Pension')
    },
    individualContributor() {
      return this.grant.pricing_level?.includes('Individual')
    },
    transactionType() {
      return this.grant.transaction_type
    },
    pricingLevels() {
      if (this.grant.transaction_type) {
        if (this.grant.transaction_type === 'Member Dues'
            || this.grant.transaction_type === 'Pension Grant') {
          return ['All Members', 'Individual Member']
        }
        return ['All Organizations', 'Individual Organization']
      }
      return []
    },
    currencySymbol() {
      return 'GHS'
    },
    selectedGrant() {
      const grant = this.grants.find(o => o.id === this.selectedGrantId)
      if (!grant) {
        return null
      }
      return {
        ...grant,
      }
    },
    grants() {
      return this.$store.getters['grants/grants']
    },
    contributor() {
      return this.$store.getters['grants/contributor']
    },
  },
  watch: {
    modalOpened(opened) {
      this.grantModalOpened = opened
    },
    id(id) {
      this.selectedGrantId = id
    },
  },
  methods: {
    resetGrantForm() {
      this.grant = { ...cont }
      this.selectedGrantId = null
      this.updateGrantMode = false
      this.$refs.grantForm.reset()
    },
    populateGrantForm(grant) {
      if (this.selectedGrant) {
        this.updateGrantMode = true
        this.grant = { ...this.selectedGrant }
      } else {
        this.resetGrantForm()
      }
    },
    closeGrantModal() {
      this.grantModalOpened = false
      this.$emit('modalClosed')
    },
    createGrant() {
      this.error = {}
      this.grantLoading = true
      this.$store.dispatch('grants/createGrant', this.grant)
        .then(res => {
          this.showSuccess('Created grant successfully')
          this.resetGrantForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create grant')
        }).finally(() => {
          this.grantLoading = false
        })
    },
    updateGrant() {
      this.error = {}
      if (!this.grant.id) {
        return
      }
      this.grantLoading = true
      this.$store.dispatch('grants/updateGrant', this.grant)
        .then(res => {
          this.showSuccess('Updated grant successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update grant')
        }).finally(() => {
          this.grantLoading = false
        })
    },
    deleteGrant() {
      if (!this.selectedGrantId) {
        this.showInfo('Please select a grant')
        return
      }
      this.deletingGrant = true
      this.$store.dispatch('grants/deleteGrant', this.selectedGrantId)
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.resetGrantForm()
        })
        .catch(() => {
          this.showError('Failed to delete grant')
        }).finally(() => {
          this.deletingGrant = false
        })
    },
  },
}
</script>
