<template>
  <div class="">
    <am-list-page
      title="Received Grants"
      :columns="columns"
      :rows="grants"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-button
            icon="SettingsIcon"
            label="Grants"
            tool-tip-text="Setup & Manage Contribution Grants"
            class=""
            @click="showGrantSetupModal()"
          />
        </div>
      </template>
      <template #action="{row}">
        <am-button
          icon="Edit2Icon"
          icon-only
          tool-tip-text="Click to edit"
          variant="flat-primary"
          @click="showGrantModalForEdit(row.item)"
        />
      </template>
      <template #amount="{row}">
        {{ row.item.amount | currency }}
      </template>
    </am-list-page>
    <grant-setup
      :id="selectedGrantId"
      :modal-opened="grantsModalOpened"
      @modalClosed="closeModal('grants')"
    />
  </div>
</template>

<script>
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import GrantSetup from '@/views/contribution/grants/GrantPaymentSetup.vue'

export default {
  name: 'Index',
  components: {
    GrantSetup,
    AmListPage,
    AmButton,
  },
  data() {
    return {
      tableLoading: false,
      grantsModalOpened: false,
      selectedGrantId: null,
    }
  },
  computed: {
    grants() {
      return this.$store.getters['grants/grants']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
    columns() {
      const symbol = this.currencySymbol
      return [
        {
          label: 'Receipt No',
          key: 'grant_id',
        },
        {
          label: 'Contributor Name',
          key: 'contributor_name',
        },
        {
          label: 'Contributor Phone',
          key: 'contributor_phone',
        },
        {
          label: 'Purpose',
          key: 'purpose',
        },
        {
          label: 'Mode of Name',
          key: 'mode_of_payment',
        },
        {
          label: `Amount(${symbol})`,
          key: 'amount',
        },
        {
          label: 'Transaction Date',
          key: 'date',
        },
        {
          label: 'Actions',
          key: 'action',
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('grants/fetchGrants')
  },
  methods: {
    showGrantModalForEdit(item) {
      this.selectedGrantId = item.id
      this.grantsModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'grants':
          this.grantsModalOpened = false
          this.selectedGrantId = null
          break
      }
    },
    showGrantSetupModal() {
      this.grantsModalOpened = true
    },
  },
}
</script>

<style scoped>

</style>
